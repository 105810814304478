<template>
  <div :class="className" id="content">
    <!-- 充电站使用情况情况 -->
<!--      <div class="full-class" @click="clickFun">{{ fullScreenName }}</div>-->

    <el-row :gutter="24">
      <el-col :span="6">
        <el-row :gutter="24">
          <el-col :span="24">
            <!--近七天充值金额-->
            <div class="line-class">
              <div class="icon-class">
                <img src="~@/assets/synthesisAnalysis/lineIcon.png" class="icon-style"/>
                <div class="title-class">近七天充值金额</div>
              </div>
              <div class="chart-class">
                <order-line
                    :workWeekDay="workWeekDay"
                    :transformWeekDay="rechargeAmountWeekDay"
                    :lineColor="1"
                ></order-line>
              </div>
            </div>
            <!--近七天营业额-->
            <div class="line-class line-class-spacing">
              <div class="icon-class">
                <img src="~@/assets/synthesisAnalysis/lineIcon.png" class="icon-style"/>
                <div class="title-class">近七天营业额</div>
              </div>
              <div class="chart-class">
                <order-line
                    :workWeekDay="workWeekDay"
                    :transformWeekDay="moneyWeekDay"
                    :lineColor="2"
                ></order-line>
              </div>
            </div>
            <!--近七天订单数-->
            <div class="line-class line-class-spacing">
              <div class="icon-class">
                <img src="~@/assets/synthesisAnalysis/lineIcon.png" class="icon-style"/>
                <div class="title-class">近七天订单数</div>
              </div>
              <div class="chart-class">
                <order-line
                    :workWeekDay="workWeekDay"
                    :transformWeekDay="indentNumWeekDay"
                    :lineColor="1"
                ></order-line>
              </div>
            </div>
            <!--近七天充电用户-->
            <div class="line-class line-class-spacing">
              <div class="icon-class">
                <img src="~@/assets/synthesisAnalysis/lineIcon.png" class="icon-style"/>
                <div class="title-class">近七天充电用户</div>
              </div>
              <div class="chart-class">
                <order-line
                    :workWeekDay="workWeekDay"
                    :transformWeekDay="userNumWeekDay"
                    :lineColor="2"
                ></order-line>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="12">
        <el-row :gutter="24">
          <div class="show-card-class">
            <!--充值金额-->
            <div class="cards-class">
              <div class="card-title">
                <img src="~@/assets/synthesisAnalysis/rechargeAmount.png" class="title-icon"/>
                <span class="title-text">充值金额</span>
              </div>
              <div class="today-money">
                <div class="money-class money-today-color">{{rechargeAmount.today}}</div>
                <img src="~@/assets/synthesisAnalysis/down.png" v-show="rechargeAmount.answerNum <= 0" class="down-icon"/>
                <img src="~@/assets/synthesisAnalysis/up.png" v-show="rechargeAmount.answerNum > 0" class="down-icon"/>
              </div>
              <div class="money-text">今日充值金额（元）</div>
              <div class="today-money">
                <div class="money-class money-yesterday-color">{{rechargeAmount.yesterday}}</div>
              </div>
              <div class="money-text">昨日充值金额（元）</div>
            </div>
            <!--营业额-->
            <div class="cards-class">
              <div class="card-title">
                <img src="~@/assets/synthesisAnalysis/turnover.png" class="title-icon"/>
                <span class="title-text">营业额</span>
              </div>
              <div class="today-money">
                <div class="money-class money-today-color">{{todayComprehensiveData.money}}</div>
                <img src="~@/assets/synthesisAnalysis/down.png" v-show="moneyAnswerNum <= 0" class="down-icon"/>
                <img src="~@/assets/synthesisAnalysis/up.png" v-show="moneyAnswerNum > 0" class="down-icon"/>
              </div>
              <div class="money-text">今日营业额（元）</div>
              <div class="today-money">
                <div class="money-class money-yesterday-color">{{yesterdayComprehensiveData.money}}</div>
              </div>
              <div class="money-text">昨日营业额（元）</div>
            </div>
            <!--订单数-->
            <div class="cards-class">
              <div class="card-title">
                <img src="~@/assets/synthesisAnalysis/orderNumber.png" class="title-icon"/>
                <span class="title-text">订单数</span>
              </div>
              <div class="today-money">
                <div class="money-class money-today-color">{{todayComprehensiveData.IndentNum}}</div>
                <img src="~@/assets/synthesisAnalysis/down.png" v-show="indentNumAnswerNum <= 0" class="down-icon"/>
                <img src="~@/assets/synthesisAnalysis/up.png" v-show="indentNumAnswerNum > 0" class="down-icon"/>
              </div>
              <div class="money-text">今日订单数</div>
              <div class="today-money">
                <div class="money-class money-yesterday-color">{{yesterdayComprehensiveData.IndentNum}}</div>
              </div>
              <div class="money-text">昨日订单数</div>
            </div>
            <!--充电用户-->
            <div class="cards-class">
              <div class="card-title">
                <img src="~@/assets/synthesisAnalysis/chargingUser.png" class="title-icon"/>
                <span class="title-text">充电用户</span>
              </div>
              <div class="today-money">
                <div class="money-class money-today-color">{{todayComprehensiveData.userNum}}</div>
                <img src="~@/assets/synthesisAnalysis/down.png" v-show="userNumAnswerNum <= 0" class="down-icon"/>
                <img src="~@/assets/synthesisAnalysis/up.png" v-show="userNumAnswerNum > 0" class="down-icon"/>
              </div>
              <div class="money-text">今日充电用户</div>
              <div class="today-money">
                <div class="money-class money-yesterday-color">{{yesterdayComprehensiveData.userNum}}</div>
              </div>
              <div class="money-text">昨日充电用户</div>
            </div>
            <!--设备总数-->
            <div class="cards-class">
              <div class="card-title">
                <img src="~@/assets/synthesisAnalysis/totalEquipment.png" class="title-icon"/>
                <span class="title-text">设备总数</span>
              </div>
              <div class="today-money">
                <div class="money-class money-today-color">{{deviceTotal.today}}</div>
                <img src="~@/assets/synthesisAnalysis/down.png" v-show="deviceTotal.answerNum <= 0" class="down-icon"/>
                <img src="~@/assets/synthesisAnalysis/up.png" v-show="deviceTotal.answerNum > 0" class="down-icon"/>
              </div>
              <div class="money-text">今日设备总数</div>
              <div class="today-money">
                <div class="money-class money-yesterday-color">{{deviceTotal.yesterday}}</div>
              </div>
              <div class="money-text">昨日设备总数</div>
            </div>
            <!--总用户数-->
            <div class="cards-class">
              <div class="card-title">
                <img src="~@/assets/synthesisAnalysis/totalUsers.png" class="title-icon"/>
                <span class="title-text">总用户数</span>
              </div>
              <div class="today-money">
                <div class="money-class money-today-color">{{userTotal.today}}</div>
                <img src="~@/assets/synthesisAnalysis/down.png" v-show="userTotal.answerNum <= 0" class="down-icon"/>
                <img src="~@/assets/synthesisAnalysis/up.png" v-show="userTotal.answerNum > 0" class="down-icon"/>
              </div>
              <div class="money-text">今日总用户数</div>
              <div class="today-money">
                <div class="money-class money-yesterday-color">{{userTotal.yesterday}}</div>
              </div>
              <div class="money-text">昨日总用户数</div>
            </div>
          </div>
        </el-row>

      </el-col>

      <el-col :span="6">
        <div class="pie-cards-class">
          <!-- 当前插座总数-->
          <div class="pie-class pie-class-spacing">
            <div class="icon-class">
              <img src="~@/assets/synthesisAnalysis/lineIcon.png" class="icon-style"/>
              <div class="title-class">当前设备总数</div>
            </div>
            <div class="pie-card">
              <charging-pile-pie :totalData="totalCurrentDevice"></charging-pile-pie>
            </div>
          </div>
          <!-- 当前插座总数-->
          <div class="pie-class pie-class-spacing">
            <div class="icon-class">
              <img src="~@/assets/synthesisAnalysis/lineIcon.png" class="icon-style"/>
              <div class="title-class">当前插座总数</div>
            </div>
            <div class="pie-card">
              <charging-pile-pie :totalData="totalCurrentOutlets"></charging-pile-pie>
            </div>
          </div>
          <!-- 插座使用率-->
          <div class="pie-class">
            <div class="icon-class">
              <img src="~@/assets/synthesisAnalysis/lineIcon.png" class="icon-style"/>
              <div class="title-class">插座使用率</div>
            </div>
            <div class="pie-card">
              <charging-pile-pie :totalData="socketUtilizationRate"></charging-pile-pie>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>


    <!-- 地图，水图 -->
<!--      <el-row style="margin-top: 24px">-->
<!--        <el-col :span="19">-->
<!--          <div id="container"></div>-->
<!--        </el-col>-->
<!--        <el-col :span="5">-->
<!--          <el-row>-->
<!--            <el-card shadow="hover">-->
<!--              <template #header>-->
<!--                <div class="card-header">-->
<!--                  <span>订单数月同比增长</span>-->
<!--                </div>-->
<!--              </template>-->
<!--              <order-liquid-fill-chart></order-liquid-fill-chart>-->
<!--            </el-card>-->
<!--          </el-row>-->
<!--          <el-row>-->
<!--            <el-card shadow="hover" class="popular-outlets-class">-->
<!--              <template #header>-->
<!--                <div class="card-header">-->
<!--                  <span>热门充电站</span>-->
<!--                </div>-->
<!--              </template>-->
<!--              <div class="popular-outlets">-->
<!--                <el-empty :image-size="80" />-->
<!--              </div>-->
<!--            </el-card>-->
<!--          </el-row>-->
<!--        </el-col>-->
<!--      </el-row>-->
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
  nextTick,
  ref,
} from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";
import datePickerComposition from "../../../common/datePickerComposition";
import PageNation from "../../../components/base/pageNation";
import OrderLine from "./OrderLine";
import ChargingPilePie from "./ChargingPilePie";
import OrderLiquidFillChart from "./OrderLiquidFillChart";
import SynthesisAnalysis from "./SynthesisAnalysis2.vue";
import {
  byMerchantIdApi,
  ByMerchantIdApi, getOccupyDeviceApi, getSumDeviceApi, getToDayApi,
  getToDayDeviceApi, getToDayRefillApi,
  getToDayUserApi, getWeekDayApi, getWeekDayRefillApi
} from "@/api/synthesisAnalysis/synthesisAnalysis";
import {requestApi} from "@/utils/requestApi";
export default {
  name: "SynthesisAnalysis",
  components: {OrderLiquidFillChart, ChargingPilePie, OrderLine, PageNation, SynthesisAnalysis},
  inject: ['isModifyStyle'],
  setup() {
    // 可以当this来使用
    const { proxy } = getCurrentInstance();
    const {datePickerShortcut} = datePickerComposition()
    const map = shallowRef(null);
    const isFullFlag = ref(false)

    const userLevel = JSON.parse(localStorage.getItem("userInfo")).chargingMerchant.level

    const data = reactive({
      userInfo: {},
      // 默认选中tab
      // total: 0,
      // pageSize: 10,
      // currentPage: 1,

      className: 'content-class',
      // 订单金额数据
      orderData: [],
      // 用户总数
      userTotal: {
        today: 0,
        yesterday: 0,
        answerNum: 0
      },
      // 设备总数
      deviceTotal: {
        today: 0,
        yesterday: 0,
        answerNum: 0
      },
      // 充值金额
      rechargeAmount: {
        today: 0,
        yesterday: 0,
        answerNum: 0
      },
      deviceMacsList: '',
      // 今天-营业额/订单数/充电用户
      todayComprehensiveData: {
        userNum: 0,
        money:0,
        IndentNum: 0
      },
      // 昨天-营业额/订单数/充电用户
      yesterdayComprehensiveData: {
        userNum: 0,
        money:0,
        IndentNum: 0
      },
      moneyAnswerNum: 0, // 营业额->今天-昨天
      indentNumAnswerNum: 0, // 订单数->今天-昨天
      userNumAnswerNum: 0, // 充电用户->今天-昨天
      userNumWeekDay: [0,0,0,0,0,0,0], //近7天的充电用户
      moneyWeekDay: [0,0,0,0,0,0,0], //近7天的营业额
      indentNumWeekDay: [0,0,0,0,0,0,0], //近7天的订单数
      workWeekDay: [], //近7天的日期
      rechargeAmountWeekDay: [0,0,0,0,0,0,0], //近7天的充值金额
      isFullFlag:false,
      fullScreenName: '全屏',
      // 当前设备总数 传值
      totalCurrentDevice: [],
      // 当前设备总数
      totalDevice: {},
      // 当前插座总数
      totalCurrentOutlets: [],
      // 插座使用率
      socketUtilizationRate: [],
    });

    onMounted(async () => {
      //DOM初始化完成进行地图初始化
      // methods.initMap();
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      methods.getToDayDevice()
      methods.getToDayUser()
      methods.getToDayRefill()
      await methods.getWeekDayRefill()
      await methods.byMerchantId()
      await methods.getSumDevice()
      await methods.getOccupyDevice()

      // 监听页面全屏
      window.addEventListener("fullscreenchange", (e)=> {
        const flag = document.fullscreenElement
        if (!flag) {
          console.log('退出全屏')
          proxy.isFullFlag = false
          proxy.className = 'content-class'
          proxy.fullScreenName = '全屏'
        } else {
          proxy.className = 'full-screen-content-class'
          proxy.fullScreenName = '退出'
        }
      })

      // data.totalCurrentDevice = [
      //   { value: 33, name: '在线设备' },
      //   { value: 70, name: '离线设备' },
      // ]
      // data.totalCurrentOutlets = [
      //   { value: 33, name: '充电数' },
      //   { value: 30, name: '空闲数' },
      //   { value: 40, name: '故障数' },
      // ]
      // data.socketUtilizationRate = [
      //   { value: 29, name: '已使用' },
      //   { value: 60, name: '未使用' },
      // ]

    });

    const methods = {
      clickFun(){
        data.isFullFlag =!data.isFullFlag
        const element = document.getElementById('content');//指定全屏区域元素
        if(data.isFullFlag){
          // screenfull.request(element);
          element.requestFullscreen()
        }else{
          document.exitFullscreen();
        }
      },

      // 今天/昨天-设备总数
      getToDayDevice() {
        requestApi(() => {
          return getToDayDeviceApi({merchantId:data.userInfo.merchantId})
        },(res) => {
          data.deviceTotal = res.data
          data.deviceTotal.answerNum = data.deviceTotal.today - data.deviceTotal.yesterday
        })
      },
      // 今天/昨天-用户总数
      getToDayUser() {
        requestApi(() => {
          return getToDayUserApi({merchantId:data.userInfo.merchantId})
        },(res) => {
          data.userTotal = res.data
          data.userTotal.answerNum = data.userTotal.today - data.userTotal.yesterday
        })
      },
      // 今天/昨天-充值金额
      getToDayRefill() {
        requestApi(() => {
          return getToDayRefillApi({merchantId:data.userInfo.merchantId})
        },(res) => {
          data.rechargeAmount.yesterday = (res.data.yesterday / 100).toFixed(2)
          data.rechargeAmount.today = (res.data.today / 100).toFixed(2)
          data.rechargeAmount.answerNum = Number(data.rechargeAmount.today) - Number(data.rechargeAmount.yesterday)
        })
      },
      // 根据merchantId查询商户下所有充电站设备
      byMerchantId() {
        requestApi(() => {
          return byMerchantIdApi({merchantId:data.userInfo.merchantId})
        },(res) => {
          data.deviceMacsList = res.data
          if(data.deviceMacsList !== null) {
            methods.getToDay()
            methods.getWeekDay()
          } else {
            // 账户下无设备 时， 近7天的营业额/订单数/充电用户为0
            console.log('账户下无设备')
            for (let i = 0; i < 7; i++) {
              data.moneyWeekDay.push(0)
              data.userNumWeekDay.push(0)
              data.indentNumWeekDay.push(0)
            }
          }
        })
      },
      // 今天/昨天-营业额/订单数/充电用户
      getToDay() {
        requestApi(() => {
          return getToDayApi({deviceMacs:data.deviceMacsList})
        },(res) => {
          data.todayComprehensiveData = res.data[0]
          data.todayComprehensiveData.money = (data.todayComprehensiveData.money / 100).toFixed(2)
          data.yesterdayComprehensiveData = res.data[1]
          data.yesterdayComprehensiveData.money = (data.yesterdayComprehensiveData.money / 100).toFixed(2)

          data.moneyAnswerNum  = data.todayComprehensiveData.money - data.yesterdayComprehensiveData.money
          data.indentNumAnswerNum  = data.todayComprehensiveData.IndentNum - data.yesterdayComprehensiveData.IndentNum
          data.userNumAnswerNum  = data.todayComprehensiveData.userNum - data.yesterdayComprehensiveData.userNum
        })
      },
      // 近7天的营业额/订单数/充电用户
      getWeekDay() {
        requestApi(() => {
          return getWeekDayApi({deviceMacs:data.deviceMacsList})
        },(res) => {
          const weekData = res.data
          data.workWeekDay = []
          data.moneyWeekDay = []
          data.userNumWeekDay = []
          data.indentNumWeekDay = []
          if (weekData.length > 0) {
            for (let i = 0; i < weekData.length; i++) {
              const workday = weekData[i].work.substring(5,weekData[i].work.length)
              data.workWeekDay.push(workday)
              const money = (weekData[i].money / 100).toFixed(2)
              data.moneyWeekDay.push(Number(money))
              data.userNumWeekDay.push(Number(weekData[i].userNum))
              data.indentNumWeekDay.push(Number(weekData[i].IndentNum))
            }
            data.workWeekDay = data.workWeekDay.reverse()
            data.moneyWeekDay = data.moneyWeekDay.reverse()
            data.userNumWeekDay = data.userNumWeekDay.reverse()
            data.indentNumWeekDay = data.indentNumWeekDay.reverse()
            // console.log();
            // console.log();
            // console.log();
            // console.log();
          }
        })
      },
      // 近7天的充值金额
      getWeekDayRefill() {
        requestApi(() => {
          return getWeekDayRefillApi({merchantId:data.userInfo.merchantId})
        },(res) => {
          if (res.data.length > 0) {
            data.workWeekDay = []
            data.rechargeAmountWeekDay = []
            for (let i = 0; i < res.data.length; i++) {
              const money = (res.data[i].money / 100).toFixed(2)
              data.rechargeAmountWeekDay.push(Number(money))
              const workday = res.data[i].work.substring(5,res.data[i].work.length)
              data.workWeekDay.push(workday)
            }
          }
          console.log(data.rechargeAmountWeekDay,'近7天的充值金额')
        })
      },
      //当前设备总数-在线/离线
      async getSumDevice() {
        await requestApi(() => {
          return getSumDeviceApi({merchantId:data.userInfo.merchantId})
        },(res) => {
          data.totalDevice = res.data
          data.totalCurrentDevice.push({value: res.data.zaixain, name: '在线设备' })
          data.totalCurrentDevice.push({value: res.data.lixian, name: '离线设备' })
        })
      },
      // 当前插座总数
      async getOccupyDevice() {
        await requestApi(() => {
          return getOccupyDeviceApi({merchantId:data.userInfo.merchantId})
        },(res) => {
          data.totalCurrentOutlets.push({ value: res.data.occupyNum, name: '充电数' })
          // 插座总数 = portNum  充电数=occupyNum  空闲数=portNum - occupyNum -故障    故障=离线数
          const freeNum = res.data.portNum - res.data.occupyNum - data.totalDevice.lixian
          data.totalCurrentOutlets.push({ value: freeNum, name: '空闲数' })
          data.totalCurrentOutlets.push({ value: res.data.faultNum, name: '故障数' })

          // 插座使用率
          let usedRate = ((res.data.occupyNum / res.data.portNum) * 100).toFixed(2)
          usedRate = (usedRate == "NaN" || usedRate == "Infinity" ) ? 0 : Number(usedRate)
          const unusedRate = 100 - usedRate
          data.socketUtilizationRate.push({ value: usedRate, name: '已使用' })
          data.socketUtilizationRate.push({ value: unusedRate, name: '未使用' })
          console.log(data.socketUtilizationRate)
        })
      },



      initMap() {
        AMapLoader.load({
          key: "adcc38b8290344ae4714f65161f0a25d", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
          .then((AMap) => {
            console.log("查看AMap", AMap);
            this.map = new AMap.Map("container", {
              //设置地图容器id
              viewMode: "3D", //是否为3D地图模式
              zoom: 13, //初始化地图级别
              center: [113.9197423, 22.5437855], //初始化地图中心点位置
            });

            // let marker1 = new AMap.Marker({
            //   position: new AMap.LngLat(113.9197423,22.5437855),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            //   title: '北京'
            // });
            // let marker2 = new AMap.Marker({
            //   position: new AMap.LngLat(113.9897434,22.5437866),
            //   title: '北京2'
            // })

            let list = [
              {
                positionX: "113.9197423",
                positionY: "22.5437855",
                title: "北京1",
              },
              {
                positionX: "113.9897434",
                positionY: "22.5437866",
                title: "北京2",
              },
            ];

            let markerList = [];

            list.forEach((item, index) => {
              let marker = new AMap.Marker({
                position: new AMap.LngLat(item.positionX, item.positionY),
                title: item.title,
              });
              markerList.push(marker);
            });

            console.log("悄悄markerList", markerList);

            this.map.add(markerList);

            console.log("查看this.map", proxy.map);
          })
          .catch((e) => {
            console.log(e);
          });
      },
    };

    return {
      ...methods,
      ...toRefs(data),
      map,
      datePickerShortcut,
      isFullFlag,
      userLevel
    };
  },
};
</script>
<style lang="scss" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 98%;
  height: 550px;
}



.content-class{
  //height: calc(100vh - 10vh);
  height: 89.5vh;
  overflow-y: auto !important;
  overflow-x: hidden;
  background: url("~@/assets/synthesisAnalysis/background.png") no-repeat;
  background-size: 100% 100%;
  padding: 19.5px;
  .full-class {
    color:#F5F5F5;
    font-size:12px;
    position: absolute;
    left: 20px;
    top: 2px;
  }
  .line-class {
    height: 20.5vh;
    background: url("~@/assets/synthesisAnalysis/lineBox.png") no-repeat;
    .chart-class {
      //background: #1f2e3980;
    }
  }
  .line-class-spacing {
    margin-top:21px;
  }
  .icon-class {
    width: 100%;
    display: flex;
    align-items: center;
    background: url("~@/assets/synthesisAnalysis/titleStripe.png") no-repeat;
    background-size: 100% 100%;
    .icon-style{
      width: 24px;
      height: 24px;
      margin: 4px 8px 4px 6px;
    }
    .title-class {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      letter-spacing: 1px;
      text-shadow: 0px 6px 6px rgba(0,0,0,0.3);
    }
  }
  .show-card-class {
    background: rgba(90,104,113,0.3);
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(104, 238, 255, 0.64), rgba(255, 255, 255, 0)) 1 1;
    padding:28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    height: calc(100vh - 10vh - 68px);
    .cards-class {
      background: rgba(83,104,118,0.75);
      box-shadow: inset 0px 2px 4px 0px rgba(167,202,224,0.65);
      border-radius: 4px;
      width: 185px;
      padding: 14px 24px 12px 24px;
      margin-bottom: 20px;
      height: 232px;
      .card-title {
        display: flex;
        align-items: center;
        //margin-bottom: 24px;
        margin-bottom: 16px;
        .title-icon {
          width: 56px;
          height: 56px;
        }
        .title-text {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
      .today-money {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .money-class {
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
        }
        .money-today-color {
          color: #F4FD26;
        }
        .money-yesterday-color {
          color: #22E7E5;
        }
        .down-icon {
          width:16px;
          height:16px;
        }
      }
      .money-text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #EEEEEE;
        //margin-bottom: 20px;
        margin-bottom: 14px;
      }
    }
  }
  .pie-cards-class {
    height: calc(100vh - 10vh - 12px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pie-class {
      background: url("~@/assets/synthesisAnalysis/pieBox.png") no-repeat;
      background-size: 100% 100%;
      height: 100%;
      background: #1c2f36c4;
    }
    .pie-class-spacing {
      margin-bottom: 21px;
    }
  }
}




.full-screen-content-class{
  height: calc(100vh - 10vh);
  overflow-y: auto !important;
  overflow-x: hidden;
  background: url("~@/assets/synthesisAnalysis/background.png") no-repeat;
  background-size: 100% 100%;
  padding: 19.5px;
  .full-class {
    color:#F5F5F5;
    font-size:12px;
    position: absolute;
    left: 20px;
    top: 5px;
  }
  .line-class {
    height: 237px;
    background: url("~@/assets/synthesisAnalysis/lineBox.png") no-repeat;
    .chart-class {
      //background: #1f2e3980;
    }
  }
  .line-class-spacing {
    margin-top:21px;
  }
  .icon-class {
    width: 100%;
    display: flex;
    align-items: center;
    background: url("~@/assets/synthesisAnalysis/titleStripe.png") no-repeat;
    background-size: 100% 100%;
    .icon-style{
      width: 24px;
      height: 24px;
      margin: 4px 8px 4px 6px;
    }
    .title-class {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      letter-spacing: 1px;
      text-shadow: 0px 6px 6px rgba(0,0,0,0.3);
    }
  }
  .show-card-class {
    background: rgba(90,104,113,0.3);
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(104, 238, 255, 0.64), rgba(255, 255, 255, 0)) 1 1;
    padding:28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    height: 960px;
    .cards-class {
      background: rgba(83,104,118,0.75);
      box-shadow: inset 0px 2px 4px 0px rgba(167,202,224,0.65);
      border-radius: 4px;
      width: 242px;
      padding: 30px 24px;
      margin-bottom: 20px;
      height: 288px;
      .card-title {
        display: flex;
        align-items: center;
        //margin-bottom: 24px;
        margin-bottom: 16px;
        .title-icon {
          width: 56px;
          height: 56px;
        }
        .title-text {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
      .today-money {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .money-class {
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
        }
        .money-today-color {
          color: #F4FD26;
        }
        .money-yesterday-color {
          color: #22E7E5;
        }
        .down-icon {
          width:16px;
          height:16px;
        }
      }
      .money-text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #EEEEEE;
        //margin-bottom: 20px;
        margin-bottom: 14px;
      }
    }
  }
  .pie-cards-class {
    height: calc(100vh - 10vh - 12px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pie-class {
      background: url("~@/assets/synthesisAnalysis/pieBox.png") no-repeat;
      background-size: 100% 100%;
      height: 100%;
      background: #1c2f36c4;
    }
    .pie-class-spacing {
      margin-bottom: 38px;
    }
  }
}





.card-header {
display: flex;
justify-content: space-between;
align-items: center;
height: 32px;
font-size: 14px;
}

.popular-outlets-class {
margin-top: 14px;
.popular-outlets{
  height: 19vh;
  width: 15vw;
}
}

.text {
font-size: 14px;
}


// .warp {
//   height: 100%;
//   .warp-content {
//     height: 100%;
//     .main {
//       height: 550px;
//       padding: 24px;
//     }
//   }
// }
</style>
